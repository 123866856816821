import axios from "axios";
import { jwtDecode } from "jwt-decode";
import AuthProviderService from "./authProvider.service";

//const API_URL = "http://127.0.0.1:8083/api/user/";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

const findUserByEmail = async (email) => {
    //return await fetch(API_URL+`email/${email}`);
    return axios.get(`${API_URL}/api/user/email/${email}`)

    // .then( (response) => {
    //     localStorage.setItem("visitor",JSON.stringify(response.data));
    //     return response.data;
    // })
    // .catch((responsError) => {
    //     const response = responsError.response;
    //     if(localStorage.getItem("visitor") === null && response.data != null){
    //         localStorage.setItem("visitor", response.data);
    //     }
    //     return response.data;
    // });
}

const register = (user) => {
    return axios.post(`${API_URL}/auth/register`, user);
}

const login = (userLogin) => {
    return axios.post(`${API_URL}/auth/login`, userLogin)
}

const getCurrentUser = () => {    
    if (localStorage.getItem("token") != null) 
        return JSON.parse(localStorage.getItem("token"));
    return null;
};

const getUserFromToken = () => {
    const userToken = UserService.getCurrentUser();
    if (userToken != undefined && userToken != null) 
      return jwtDecode(UserService.getCurrentUser().token); 
    return null;
}

const isTokenExpired = () => {
    const userToken = UserService.getCurrentUser();
    if (userToken != undefined && userToken != null) {
      const userToken = jwtDecode(UserService.getCurrentUser().token);      
      const currentTime = Date.now() / 1000;      
      const isExpired = userToken.exp < currentTime;      // fasle => token not expired 
      return isExpired;          
    }
    return true;
}

// isExpired
const logout = () => {
    localStorage.removeItem("token");
}


const getUser = async () => {
    // if( currentUser !=  null) 
    //   return currentUser;
    // const isLogout = UserService.logout();
    const isExpired = UserService.isTokenExpired();
    if(!isExpired){
      const userToken = UserService.getUserFromToken();
      return { email: userToken.email, accountVerified: userToken.accountVerified };
    }
    return null;
};

const confirmEmail = async (token) => {    
    return axios.get(`${API_URL}/auth/confirmEmail?token=${token}`)
}

const generateToken = async (user) => {
    return axios.post(`${API_URL}/auth/generateToken`, user)
}

const resendEmailVerification = async () => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/auth/resendEmailVerification`, {headers});
}

const UserService = {
    findUserByEmail,
    register,
    login,
    getCurrentUser,
    logout,
    isTokenExpired,
    getUserFromToken,
    getUser,
    confirmEmail,
    generateToken,
    resendEmailVerification
}

export default UserService;