import axios from "axios";
import AuthProviderService from "./authProvider.service";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/tracking`;

const trackProduct = async (productId) => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/add/${productId}`, {headers});
}

const findAllTrackedProductsByPage = (page,sizePerPage) => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/findAll?page=${page}&sizePerPage=${sizePerPage}`, {headers});
}

const findTrackedProduct = (id) => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/find/${id}`, {headers});
}

const TrackingProductService = {    
    trackProduct,
    findAllTrackedProductsByPage,
    findTrackedProduct
}

export default TrackingProductService;