import axios from "axios";
import UserService from "./user.service";

// const API_URL = "http://127.0.0.1:8083/api/search";
const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/search`;

const search = async (query) => {    
    const isExpired = UserService.isTokenExpired();
    if( !isExpired ){
        const token = UserService.getCurrentUser();
        const headers = { 'Authorization': `Bearer ${token.token}` };
        return axios.get(`${API_URL}/user`, { 
            headers,
            params: {"query": query}
        });
    }
}


const searchForGuest = async (visitorId, query) => {
    return axios.get(`${API_URL}/visitor/${visitorId}`, {
        params: {"query": query}
    })
}

const findHistory = async (currentPage,itemsPerPage) => {
    const isExpired = UserService.isTokenExpired();
    if( !isExpired ){
        const token = UserService.getCurrentUser();
        const headers = { 'Authorization': `Bearer ${token.token}` };        
        return axios.get(`${API_URL}/history?page=${currentPage}&sizePerPage=${itemsPerPage}`, { headers });
    }
}

const SearchService = {
    searchForGuest,
    findHistory,
    search
}

export default SearchService;