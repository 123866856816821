import React, { useEffect, useState } from 'react';
import Prodcut from '../components/product.component';
import Search from '../components/search.component';
import * as bootstrap from 'bootstrap';
import ProductService from '../services/product.service';
import Pagination from '../components/pagination.component';

import toast, { Toaster } from 'react-hot-toast';
import Toast from '../components/toast.component';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';



const showDropdown = () => {
    const element = document.querySelector(".filter-by-price");
    if (element != null && element != undefined) {
        const dropdown = new bootstrap.Dropdown(element);
        dropdown.show()
    }
}
const closeDropdown = () => {
    const element = document.querySelector(".filter-by-price");
    if (element != null && element != undefined) {
        const dropdown = new bootstrap.Dropdown(element);
        dropdown.hide();
    }
}

const notify = (title, message, type) => {
    toast.custom(
        <Toast title={title} message={message} type={type} />, {
        duration: 4000,
    }
    );
}

const ProductSearch = ({ user }) => {


    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();


    const [totalProductFound, setTotalProductFound] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);

    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState(null);
    const [status, setStatus] = useState(null);
    const [result, setResult] = useState(null);
    const [loader, setLoader] = useState(false);
    const [badges, setBadges] = useState([]);


    // contains products 
    const handleChangeKeyword = (value) => { setResult(value) };

    const updateLoader = (value) => setLoader(value);
    const handlePagination = (pageNumber) => { setCurrentPage(pageNumber); };

    const findAllByPage = () => {

        const sortDirection = searchParams.get('sortDirection');
        const minPrice = searchParams.get('minPrice');
        const maxPrice = searchParams.get('maxPrice');
        //debugger;            
        const allParamsIsNull = (sortDirection == null && minPrice == null && maxPrice == null);
        // !allParamsIsNull && 
        if (search != null) {
            const resultFindProductByPage = ProductService.findAllByPage(currentPage, productsPerPage, search?.id, sortDirection, minPrice, maxPrice);
            resultFindProductByPage
                .then(response => response.data)
                .then(data => {
                    // debugger;
                    setProducts(data.content);
                    setTotalProductFound(data.totalElements);
                    setBadges([]);
                    if (sortDirection !== null) {
                        // "Price: Ascending" or "Price: Descending"
                        const sort = { text: `Sort by Price: ${sortDirection}`, filterType: "sortDirection" };
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [sort];
                            else return [...prevBadges, sort]
                        });
                    }
                    if (minPrice !== null && maxPrice !== null) {
                        const intervalPrices = { text: `Price: ${minPrice} - ${maxPrice}`, filterType: "filterByPrice" };
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [intervalPrices];
                            else return [...prevBadges, intervalPrices]
                        });
                    }
                })
                .catch(responseError => console.error(responseError));
        }
    }

    const updateUrl = (sort = null, maxPriceInterval = null, minPriceInterval = null) => {
        const params = {};
        const query = searchParams.get('query');
        const sortDirection = searchParams.get('sortDirection');
        const minPrice = searchParams.get('minPrice');
        const maxPrice = searchParams.get('maxPrice');
        // debugger;
        if (query != null) params.query = query;

        if (sortDirection != null) params.sortDirection = sortDirection;
        if (sort != null) params.sortDirection = sort;

        if (maxPrice != null && minPrice != null) {
            params.maxPrice = maxPrice;
            params.minPrice = minPrice;
        }
        if (maxPriceInterval != null && minPriceInterval != null) {
            params.maxPrice = maxPriceInterval;
            params.minPrice = minPriceInterval;
        }

        /* const paramsUrlsIsNull =  maxPrice == null && minPrice == null && sortDirection == null ;
        const paramsPassedAsparamsIsNull =  minPriceInterval == null && maxPriceInterval == null && sort == null ;

        if(paramsUrlsIsnull && paramsPassedAsparamsIsNull) return; */

        navigate({
            search: `?${createSearchParams(params)}`,
        });
    }

    useEffect(() => {
        const totalNbpage = Math.ceil(totalProductFound / productsPerPage);
        const isCurrentPageValid = (currentPage <= totalNbpage && currentPage > 0);
        if (products.length != 0 && isCurrentPageValid) {
            const sortDirection = searchParams.get('sortDirection');
            const minPrice = searchParams.get('minPrice');
            const maxPrice = searchParams.get('maxPrice');
            const resultFindProductByPage = ProductService.findAllByPage(currentPage, productsPerPage, search?.id, sortDirection, minPrice, maxPrice);
            resultFindProductByPage
                .then(response => {
                    return response.data
                })
                .then(data => {
                    setProducts(data.content);
                    setTotalProductFound(data.totalElements)
                })
                .catch(responseError => console.error(responseError));
            setLoader(false);
        }

        if (products.length != 0 && !isCurrentPageValid) {
            if (currentPage == 0) setCurrentPage(1);
            if (currentPage > totalNbpage) setCurrentPage(totalNbpage);
        }
    }, [currentPage]);


    useEffect(() => {
        if (result != null && result != undefined && Object.keys(result).length > 0) {
            const response = result?.data;
            const data = response?.resultSearch;
            setStatus(response.status);
            if (response.status === 'SEARCH_LIMIT_EXCEEDED_PER_DAY') {
                setLoader(false);
                const message = "You've made the maximum number of requests allowed for today. Sign in to get additional access, or come back tomorrow!";
                const title = "Friendly Reminder";
                notify(title, message, "WARNING");
            }
            if (response.status === 'NOT_VERIFIED') {
                setLoader(false);
                const message = "Kindly check your inbox and click the verification link to confirm your email";
                const title = "Confirmation Email";
                notify(title, message, "WARNING");
            }
            if (response.status === 'SUCCESS') {
                setProducts(response.resultSearch);
                setSearch(response.search);
                setTotalProductFound(response.totalProductFound);
                setLoader(false);
            }
        } else {
            /* setLoader(false);
            setStatus("ERROR"); */
        }

    }, [result]);

    const sortProduct = (sortDirection) => {
        updateUrl(sortDirection, null, null);
    }


    /*     useEffect(() => {
            const query = searchParams.get('query');
            if (query != null && query != "") {
                setLoader(true);
            }
        }, []); */

    useEffect(() => {
        const query = searchParams.get('query');
        if (query != null && query != "") {
            setLoader(true);
        }

        const totalNbpage = Math.ceil(totalProductFound / productsPerPage);
        const isCurrentPageValid = (currentPage <= totalNbpage && currentPage > 0);
        // debugger;

        // check if all parms is null 
        //if (products.length != 0 && isCurrentPageValid) {

        /* const sortDirection = searchParams.get('sortDirection');
        const minPrice = searchParams.get('minPrice');
        const maxPrice = searchParams.get('maxPrice');            
            
        const allParamsIsNull = (sortDirection == null  && minPrice == null && maxPrice == null);
        if(!allParamsIsNull && search != null){
            const resultFindProductByPage = ProductService.findAllByPage(currentPage, productsPerPage, search?.id, sortDirection, minPrice, maxPrice);
            resultFindProductByPage
                .then(response => response.data)
                .then(data => {
                    // debugger;
                    setProducts(data.content);
                    setTotalProductFound(data.totalElements);
                    setBadges([]);
                    if (sortDirection !== null) {
                        // "Price: Ascending" or "Price: Descending"
                        const sort = `Sort by Price: ${sortDirection}`;
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [sort];
                            else return [prevBadges, sort]
                        });
                    }
                    if (minPrice !== null && maxPrice !== null) {
                        const intervalPrices = `Price: ${minPrice} - ${maxPrice}`;
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [intervalPrices];
                            else return [prevBadges, intervalPrices]
                        });
                    }
                })
                .catch(responseError => console.error(responseError));
        } */

        findAllByPage();

        setLoader(false);
        // }
        /* if (products.length != 0 && !isCurrentPageValid) {
            if (currentPage == 0) setCurrentPage(1);
            if (currentPage > totalNbpage) setCurrentPage(totalNbpage);
        } */
    }, [location]);

    useEffect(() => {

        const totalNbpage = Math.ceil(totalProductFound / productsPerPage);
        const isCurrentPageValid = (currentPage <= totalNbpage && currentPage > 0);

        /* const sortDirection = searchParams.get('sortDirection');        
        const minPrice = searchParams.get('minPrice');
        const maxPrice = searchParams.get('maxPrice');            
            
        const allParamsIsNull = (sortDirection == null && minPrice == null && maxPrice == null);
        
        if(!allParamsIsNull &&  search != null){
            const resultFindProductByPage = ProductService.findAllByPage(currentPage, productsPerPage, search?.id, sortDirection, minPrice, maxPrice);
            resultFindProductByPage
                .then(response => response.data)
                .then(data => {
                    // debugger;
                    setProducts(data.content);
                    setTotalProductFound(data.totalElements);
                    setBadges([]);
                    if (sortDirection !== null) {
                        const sort = `Sort by Price: ${sortDirection}`;
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [sort];
                            else return [prevBadges, sort]
                        });
                    }
                    if (minPrice !== null && maxPrice !== null) {
                        const intervalPrices = `Price: ${minPrice} - ${maxPrice}`;
                        setBadges((prevBadges) => {
                            if (prevBadges.length == 0) return [intervalPrices];
                            else return [prevBadges, intervalPrices]
                        });
                    }
                })
                .catch(responseError => console.error(responseError));
        } */

        findAllByPage();
        setLoader(false);
        // }

    }, [search]);

    const formik = useFormik({
        initialValues: {
            minPrice: '',
            maxPrice: '',
        },
        validationSchema: Yup.object({

            minPrice: Yup.number()
                .typeError('Minimum price must be a number')
                .min(0, 'Minimum price cannot be negative')
                .required('Minimum price is required'),
            maxPrice: Yup.number()
                .typeError('Maximum price must be a number')
                .positive('Maximum price must be a positive number')
                .required('Maximum price is required')
                .moreThan(Yup.ref('minPrice'), 'Maximum price must be greater than minimum price'),
        }),
        onSubmit: async (values) => {
            if (formik.isValid) {
                /* const params = {};
                const query = searchParams.get('query');
                const sortDirection = searchParams.get('sortDirection');

                if (query != null) params.query = query;
                if (sortDirection != null) params.sortDirection = sortDirection;

                params.minPrice = values.minPrice;
                params.maxPrice = values.maxPrice;
                navigate({
                    search: `?${createSearchParams(params)}`,
                }); */
                updateUrl(null, values.maxPrice, values.minPrice);
            }
        },
    });

    const removeFilter = (filterType) => {
        // remove filter from badges 
        // send request with new filter
        // filterByPrice sortDirection

        setBadges((prevBadges) => prevBadges.filter(ele => ele.filterType != filterType));
        removeParamsFromUrl(filterType);
    }

    const removeParamsFromUrl = (param) => {
        const params = {};
        const query = searchParams.get('query');
        const sortDirection = searchParams.get('sortDirection');
        const minPrice = searchParams.get('minPrice');
        const maxPrice = searchParams.get('maxPrice');
        // filterByPrice sortDirection
        if (query != null) params.query = query;
        if (param != "sortDirection" && sortDirection != null) params.sortDirection = sortDirection;

        if (param != "filterByPrice" && (maxPrice != null && minPrice != null)) {
            params.maxPrice = maxPrice;
            params.minPrice = minPrice;
        }

        navigate({
            search: `?${createSearchParams(params)}`,
        });
    }



    return (
        <div className="container" style={{ "marginTop": "120px" }}>
            <Toaster />
            <div className="row d-flex justify-content-center" style={{}}>
                <div className="col-lg-6">
                    <Search
                        onChangeKeyword={handleChangeKeyword}
                        loader={updateLoader}
                    />
                </div>
            </div>

            {
                loader === true &&
                <div className='row d-flex justify-content-center mt-5' >
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden" >Loading...</span>
                    </div>
                </div>
            }

            <div className='row d-flex justify-content-center'>

                <div className='d-flex justify-content-center mb-5 mt-5'>
                    {status != null && loader != true &&
                        <>
                            {(() => {
                                switch (status) {
                                    case 'NOT_VERIFIED':
                                        return <p style={{ fontWeight: "bold" }} >
                                            Please verify your email in order to proceed with the search. <br />
                                            Check your inbox for the verification email</p>

                                    case 'ERROR':
                                        return <p style={{ fontWeight: "bold" }} > No results were found. Please try again or check back later.</p>

                                    default:
                                        return null
                                }
                            })()}
                        </>
                    }

                </div>

                {/* Start Filter and badges  */}
                <div className='row d-flex justify-content-center'>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className='col-lg-6 col-md-5 col-sm-12 col-12 mb-3'>
                                {
                                    badges.map((badge, index) => {
                                        return <span
                                            className="badge text-bg-primary"
                                            key={index}
                                            style={{ margin: "0px 7px 7px 0px" }}>
                                            <strong> {badge.text}  </strong>
                                            <i
                                                className="fa-regular fa-circle-xmark"
                                                onClick={() => removeFilter(badge.filterType)}
                                                style={{ marginLeft: "3px", cursor: "pointer" }}></i>
                                        </span>
                                    })
                                }
                            </div>
                            <div className='col-lg-6 col-md-7 col-sm-12 col-12 mb-3'>
                                <div className='d-flex flex-wrap float-end'>
                                    <div className='mr-2 mb-2'>
                                        <span
                                            className="btn btn-light btn-sm"
                                            onClick={() => sortProduct("Desc")}>
                                            <i style={{ color: "#1976D2" }} className="fa-solid fa-arrow-down-1-9"></i>
                                            <span style={{ fontWeight: "500", fontSize: "0.98rem", marginLeft: "7px" }}>Desc by price</span>
                                        </span>
                                    </div>
                                    <div className='mr-2 mb-2'>
                                        <span
                                            className="btn btn-light btn-sm"
                                            onClick={() => sortProduct("Asc")}>
                                            <i style={{ color: "#1976D2" }} className="fa-solid fa-arrow-up-9-1"></i>
                                            <span style={{ fontWeight: "500", fontSize: "0.98rem", marginLeft: "7px" }}>Asc by price</span>
                                        </span>
                                    </div>

                                    <div className='mr-2 mb-2 d-xxl-none d-xxl-block d-xl-none d-lg-none d-md-none'>
                                        <button
                                            className="btn btn-light btn-sm"
                                            data-bs-toggle="modal"
                                            data-bs-target="#filterByPriceModal"
                                        >
                                            <i style={{ color: "#1976D2" }} className="fa-solid fa-filter"></i>
                                            <span style={{ fontWeight: "500", fontSize: "0.98rem", marginLeft: "7px" }}>Filter</span>
                                        </button>
                                    </div>

                                    <div className="dropdown mb-2 d-none d-sm-none d-md-block">
                                        <button
                                            className="btn btn-light dropdown filter-by-price btn-sm"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            data-bs-auto-close="outside"
                                            onClick={showDropdown}
                                        >
                                            <i style={{ color: "#1976D2" }} className="fa-solid fa-filter"></i>
                                            <span style={{ fontWeight: "500", fontSize: "0.98rem", marginLeft: "7px" }}>Filter</span>
                                        </button>

                                        <form
                                            className="dropdown-menu"
                                            style={{ width: "350px", marginTop: "35px", padding: "0px" }}
                                            onSubmit={formik.handleSubmit}
                                        >

                                            <div className='d-flex justify-content-between' style={{ backgroundColor: "#eeeeee", padding: "4.5px 14px", borderBottom: "1px solid #bdbdbd" }}>
                                                <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>Filter</span>
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={closeDropdown}
                                                > <i className="fa-solid fa-xmark"></i>
                                                </span>
                                            </div>
                                            <div className="row p-3">
                                                <span style={{ fontSize: "0.98rem", fontWeight: "500", marginBottom: "7.5px" }}>Price Range</span>
                                                <div className="col-lg-6">
                                                    <input
                                                        className="form-control form-control-sm"
                                                        name="minPrice"
                                                        type="number"
                                                        placeholder="Min price"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.minPrice} />
                                                    {formik.touched.minPrice && formik.errors.minPrice ? (
                                                        <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.minPrice}</div>
                                                    ) : null}
                                                </div>
                                                <div className="col-lg-6">
                                                    <input
                                                        className="form-control form-control-sm"
                                                        type="number"
                                                        name="maxPrice"
                                                        placeholder="max price"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.maxPrice}
                                                    />
                                                    {formik.touched.maxPrice && formik.errors.maxPrice ? (
                                                        <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.maxPrice}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end mt-2" style={{ padding: "2px 12px 10px 0px" }}>
                                                <button type="submit" className="btn btn-primary btn-sm">Apply Now</button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Filter and badges  */}

                <div className='d-flex justify-content-center mb-5 mt-4'>
                    {totalProductFound !== null && <p className="fs-4" >Results show : <span className='fw-semibold'>{totalProductFound} option found</span> </p>}
                </div>


                {products.map((product, index) => {
                    return <div className='row d-flex justify-content-center' key={index}>
                        <Prodcut key={index} user={user} product={product} />
                    </div>
                })}

                {products.length != 0 &&
                    <Pagination
                        length={totalProductFound}
                        itemPerPage={productsPerPage}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                    />
                }

            </div>

            {/* Start Filter by price on small devicess */}
            <div className="modal fade" id="filterByPriceModal" tabIndex="-1" aria-labelledby="filterByPriceModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <span className="modal-title" id="filterByPriceModalLabel" style={{ fontSize: "1.09rem", fontWeight: "bold" }}>Filter</span>
                            <span data-bs-dismiss="modal" aria-label="Close" className='close-notifications'><i className="fa-solid fa-xmark"></i></span>
                        </div>
                        <div className="modal-body"
                            style={{ padding: "0px", maxHeight: "300px", maxHeight: "350px", overflowY: "scroll" }}
                        >
                            <form
                                className=""
                                style={{ width: "350px", marginTop: "35px", padding: "0px" }}
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="row pr-3 pl-3 pb-3">
                                    <span style={{ fontSize: "0.98rem", fontWeight: "500", marginBottom: "7.5px" }}>Price Range</span>
                                    <div className="col-lg-12">
                                        <input
                                            className="form-control form-control-sm"
                                            name="minPrice"
                                            type="number"
                                            placeholder="Min price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.minPrice} />
                                        {formik.touched.minPrice && formik.errors.minPrice ? (
                                            <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.minPrice}</div>
                                        ) : null}
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <input
                                            className="form-control form-control-sm"
                                            type="number"
                                            name="maxPrice"
                                            placeholder="max price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.maxPrice}
                                        />
                                        {formik.touched.maxPrice && formik.errors.maxPrice ? (
                                            <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.maxPrice}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-2" style={{ padding: "2px 12px 10px 0px" }}>
                                    <button type="submit" className="btn btn-primary btn-sm">Apply Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Filter by price on small devicess */}

        </div>
    )
}

export default ProductSearch;