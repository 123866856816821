import React, { useEffect, useState } from "react";
import * as bootstrap from 'bootstrap';
import SearchService from "../services/search.servie";
import { Link } from "react-router-dom";
import Pagination from "../components/pagination.component";

const showDropdown = (e) => {
    if (e.target != null && e.target != undefined) {
        const dropdown = new bootstrap.Dropdown(e.target);
        dropdown.show()
    }
}


const formattedDate = (dateTime) => {
    const date = new Date(dateTime);
    const optionsDate = { month: 'short', day: '2-digit', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate); // "Aug 04 2024"
    return formattedDate;
}
const formattedTime = (dateTime) => {
    const date = new Date(dateTime);
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    return formattedTime;
}

const HistoryPage = () => {

    const [histories, setHistories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totaleItems, setTotaleItems] = useState(null);
    const [loader, setLoader] = useState(true);

    const handlePagination = (pageNumber) => { setCurrentPage(pageNumber); };

    useEffect(() => {
        SearchService.findHistory(currentPage, itemsPerPage)
            .then((response) => response.data)
            .then((data) => {
                setHistories(data.content);
                setTotaleItems(data.totalElements);
                setLoader(false)
            })
            .catch((errorResponse) => console.error(errorResponse));

    }, [currentPage]);

    return (
        <div>
            <div className="row">
                <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Your History of search</h1>            

                {/* <div className="col-lg-12">
                    <div className="dropdown float-end">
                        <button className="btn btn-secondary btn-sm dropdown-toggle disabled" type="button" data-bs-toggle="dropdown" onClick={showDropdown} aria-expanded="false">
                            Past 3 month
                        </button>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Past 24 hours</a></li>
                            <li><a className="dropdown-item" href="#">Past 2 month</a></li>
                            <li><a className="dropdown-item" href="#">Past 6 month</a></li>
                        </ul>
                    </div>
                </div> */}

                <div className="col-lg-12 mt-3">
                    {histories.map((history, index) => {
                        return <div className="mt-3" key={index} style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "20px" }}>
                            <h2 style={{ fontSize: "1.4rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>{formattedDate(history.searchTime)}</h2>
                            <div className="row" id={`${history.id}`}>
                                <div className="col-lg-2">
                                    <span style={{ color: "#424242", fontWeight: "500" }}>  {formattedTime(history.searchTime)} </span>
                                </div>
                                <div className="col-lg-10 shadow-sm" style={{ padding: "10px" }}>

                                    <h3 className="mb-3" style={{ fontSize: "1.2rem", color: "#424242", fontWeight: "500" }}>{history.query}</h3>
                                    <div className="mb-3">
                                        <span style={{ fontSize: "1.09rem", color: "#0277bd", fontWeight: "500" }}>{history.sizeProducts} product found</span>
                                    </div>
                                    <div>
                                        <Link to={`/search?query=${history.query}`}>
                                            <button type="button" className="btn btn-success btn-sm mr-2">Search it again</button>
                                        </Link>
                                        <button type="button" className="btn btn-light btn-sm ml-2" disabled >View result</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                    }
                </div>

                {
                    (loader === true && histories.length === 0) &&
                    <div className='row d-flex justify-content-center mt-5' >
                        <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden" >Loading...</span>
                        </div>
                    </div>
                }

                {
                (histories.length === 0 && loader === false) &&
                    <div className="d-flex justify-content-center">
                        <p style={{ fontSize: "1.2rem" }}>No past activity found.</p>
                    </div>
                }

                {histories.length != 0 &&
                    <Pagination
                        length={totaleItems}
                        itemPerPage={itemsPerPage}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                    />
                }

            </div>


        </div>
    );
}

export default HistoryPage;