import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {


    return (
        <div className='footer'>
            <div className="container-fluid bg-light shadow-sm"
                style={{
                    backgroundColor: "gainsboro",  marginTop: "30px"
                }}>
                <div className="container">
                    <footer className="p-2 ">
                        <div className="row pt-1 mt-3 d-flex justify-content-center align-items-center">

                            <div className="col-12 col-lg-2 mb-3 d-flex justify-content-center">
                                <span style={{ fontSize: "1.98rem", color: "#1767D2" }}>Bubeph</span>
                            </div>

                            <div className="col-6 col-lg-3 d-flex justify-content-center">
                                <div>
                                    <h5 style={{ fontSize: "1.38rem", fontWeight: "500", marginBottom: "15px" }}>About us</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Team</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-6 col-lg-3 d-flex justify-content-center">
                                <div>
                                    <h5 style={{ fontSize: "1.38rem", fontWeight: "500", marginBottom: "15px" }}>Support</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2">
                                            <Link to="/contactus">
                                                <span className="nav-link p-0 text-body-secondary">Contact us</span>
                                            </Link>

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="col-6 col-md-2 mb-3">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Home</a></li>
                                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Features</a></li>
                                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">Pricing</a></li>
                                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">FAQs</a></li>
                                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">About</a></li>
                            </ul>
                        </div> */}


                        </div>

                        <div className="d-flex justify-content-center py-4 my-4 border-top">
                            <p>&copy; 2024 Bubeph. All rights reserved.</p>
                            {/* <ul className="list-unstyled d-flex">
                                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                                <li className="ms-3"><a className="link-body-emphasis" href="#"><svg className="bi" width="24" height="24"></svg></a></li>
                            </ul> */}
                        </div>
                    </footer>

                </div>
            </div>

        </div>

    )

}

export default Footer;