import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserService from "../services/user.service";

import * as bootstrap from 'bootstrap';
import { useNavigate } from "react-router-dom";
import Toast from "../components/toast.component";
import toast, { Toaster } from "react-hot-toast";


const Register = () => {
  const navigate = useNavigate();
  
  const notify = (title, message, type) => {
    toast.custom(
      <Toast title={title} message={message} type={type} />, {
      duration: 4000,
    }
    );
  }


  const [messageErrorEmail, setMessageErrorEmail] = useState("");

  const checkIfEmailExist = async (value) => {
    if (!value) return true;
    try {
      const response = await UserService.findUserByEmail(value);
      return response.data.exists;
    } catch (responsError) {
      if (responsError.response.status === 404)
        return true;
    }
  }


  const handleBlurEmail = () => {
    //formik.handleBlur;
    if (!formik.errors.email) {
      formik.setFieldValue("checkEmail", true);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      // if email already exist
      //checkEmail: Yup.boolean(),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      // .when("checkEmail",{ // remove check 
      //   is: true,
      //   then: () => Yup.string().test({ 

      //     message : () => "Email already exists",
      //     test : async (value) => {
      //       //console.log(value)
      //       if (!value) return true;
      //       try {                        
      //         const response = await UserService.findUserByEmail(value);
      //         return response.data.exists;
      //       } catch (responsError) {
      //         //console.log(responsError.response.status);
      //         if( responsError.response.status === 404)
      //           return true;
      //       }
      //     }
      //   })
      // }),

      password: Yup.string()
        .max(100, 'Must be 100 characters or less')
        .min(5, 'Must be 5 characters or more')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required')
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        // insert data        
        try {
          // check if the user already exist
          const userResponse = await UserService.findUserByEmail(values.email);
          if (Object.keys(userResponse.data).length != 0 && userResponse.status === 200) {
            //setMessageErrorEmail(`Email ${values.email} already exists`);
            notify("Sign up", `Email ${values.email} already exists`, "WARNING");
            return;
          }

          const response = await UserService.register(values);
          if (response.status === 200) {
            notify("Sign up", 
              `Registration successful! You have been successfully registered. Welcome!
              Please check your email for a verification link to confirm your account.`, 
              "SUCCESS");
            setTimeout(() => {
              navigate("/login");
            }, 1880);
          }
        } catch (responsError) {
          console.log(responsError);
          //if( responsError.response.status === 404)
          //  return true;
        }

      }
    },
  });



  return (
    <div className="container-fluid">
      <Toaster />
      <div className="row d-flex justify-content-center">
        <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5 col-8 shadow-sm" style={{ "marginTop": "150px", "padding": "13px" }}>
          <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Get started</h3>
          <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Create your account now</h5>

          <form onSubmit={formik.handleSubmit}>

            <div className="" style={{ "position": "relative" }}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="email"
                  name="email"
                  placeholder="Example@exmaple.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.email}</div>
                ) : null}
                {messageErrorEmail != "" ? (
                  <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{messageErrorEmail}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-control form-control-sm"
                  placeholder="At least 5 characters"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.password}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="form-control form-control-sm"
                  placeholder="Confirm password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.confirmPassword}</div>
                ) : null}
              </div>

              <div >
                <button type="submit"
                  className="btn btn-primary btn-sm"
                  style={{ "backgroundColor": 'red !important' }}
                >Sign up</button>
              </div>
            </div>

          </form>
        </div>

      </div>


    </div>
  );
};

export default Register;