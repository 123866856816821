import axios from "axios";
import AuthProviderService from "./authProvider.service";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/notification`;


const findAllByPage = (page,sizePerPage) => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/findAll?page=${page}&sizePerPage=${sizePerPage}`, {headers});
}

const markNotificationAsRead = (id) => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.put(`${API_URL}/${id}/markAsRead`, {}, {headers});
}

const markAllNotificationAsRead = () => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.put(`${API_URL}/markAllAsRead`, {}, {headers});
}
const countUnreadNotifications = () => {
    const headers  = AuthProviderService.getAuthorizationHeader();
    return axios.get(`${API_URL}/countRead`, {headers});
}

const NotificationService = {
    findAllByPage,
    markNotificationAsRead,
    countUnreadNotifications,
    markAllNotificationAsRead
}

export default NotificationService;