import React, { useEffect }  from "react";
import toast from "react-hot-toast";
// import * as bootstrap from 'bootstrap';

// const toastTypes = {
//     success: {
//       icon: <IconCircleCheckFilled />,
//       iconClass: "success-icon",
//       progressBarClass: "success",
//     },
//     warning: {
//       icon: <IconAlertCircleFilled />,
//       iconClass: "warning-icon",
//       progressBarClass: "warning",
//     },
//     info: {
//       icon: <IconInfoCircleFilled />,
//       iconClass: "info-icon",
//       progressBarClass: "info",
//     },
//     error: {
//       icon: <IconCircleXFilled />,
//       iconClass: "error-icon",
//       progressBarClass: "error",
//     },
//   };

const alertColor = {
    INFO: "#cfe2ff",
    WARNING: "#fff3cd",
    DANGER: "#f8d7da",
    SUCCESS: "#cfe2ff"
}
// display,
const Toast = ({ title, message, type}) => {
    return (
        <div className=" p-1">
            {/* top-0 start-50  */}
            <div className="">
                <div id="liveToast" className="custom-toast">
                    <div className="toast-header">
                        <svg className="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
                            <rect width="100%" height="100%" fill={alertColor[type]}></rect>
                        </svg>
                        <strong className="me-auto">{title}</strong>
                    </div>
                    <div className="toast-body" >{message}</div>
                </div>
            </div>
        </div>
    );
}

export default Toast;