import React, { useEffect, useState } from "react";
import * as bootstrap from 'bootstrap';
import ProductService from "../services/product.service";
import TrackingProductService from "../services/trackingProduct.service";
import Pagination from "../components/pagination.component";
import { Link } from "react-router-dom";

const showDropdown = (e) => {
    if (e.target != null && e.target != undefined) {
        const dropdown = new bootstrap.Dropdown(e.target);
        dropdown.show()
    }
}
const formatDate = (createdAt) => {
    const date = new Date(createdAt)
    const formattedDate = date.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
    return formattedDate;
}

const formatStatus = (status) => {
    const lowerCaseStatus = status.replaceAll("_", " ").toLowerCase();
    return lowerCaseStatus.charAt(0).toUpperCase() + lowerCaseStatus.slice(1).toLowerCase();
}

const TrackedProductsPage = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [trackedProducts, setTrackedProducts] = useState([]);
    const [totalProductFound, setTotalProductFound] = useState(null);
    const [loader, setLoader] = useState(true);
    const handlePagination = (pageNumber) => { setCurrentPage(pageNumber); };

    useEffect(() => {        
        TrackingProductService.findAllTrackedProductsByPage(currentPage, productsPerPage)
            .then((response) => response.data)
            .then((data) => {
                setTotalProductFound(data.totalElements);
                setTrackedProducts(data.content);
                setLoader(false);
            })
            .catch((error) => console.error(error));
    }, [currentPage])

    return (
        <div>
            <div className="row">
                <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Tracked Product</h1>

                {
                    loader === true &&
                    <div className='row d-flex justify-content-center mt-5' >
                        <div className="spinner-border text-info" role="status">
                            <span className="visually-hidden" >Loading...</span>
                        </div>
                    </div>
                }

                {trackedProducts.length != 0 &&
                    <div className="col-lg-12 col-md-12 shadow-sm d-sm-none d-md-block d-none">

                        <div className="row" style={{ backgroundColor: "#f5f5f5", padding: "8px", border: "1px solid #eeeeee" }}>
                            <div className="col-lg-5 col-md-5">
                                <span className="" style={{ "cursor": "pointer", fontSize: "1.09rem" }}>Products</span>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <span className="" style={{ "cursor": "pointer", fontSize: "1.09rem" }}>Price</span>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <span className="" style={{ "cursor": "pointer", fontSize: "1.09rem" }}>Status</span>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <span className="" style={{ "cursor": "pointer", fontSize: "1.09rem" }}>Tracked at</span>
                            </div>
                            <div className="col-lg-1 col-md-1">
                            </div>
                        </div>

                        {trackedProducts.map((trackedProduct, index) => {
                            return <div className="row" style={{ padding: "8px", borderBottom: "1px solid #eeeeee" }} key={index}>
                                <div className="col-lg-1 col-md-1">
                                    <img
                                        src={trackedProduct.product.urlImage}
                                        className="img-fluid"
                                        style={{ "width": "auto", "height": "56px", "borderRadius": "5px" }}
                                        alt="..." />
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <h3
                                        className="truncate-multi-line"
                                        style={{ "fontWeight": "bold", "cursor": "pointer", "fontSize": "1.1rem", }} >
                                        {trackedProduct.product.title}
                                    </h3>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    {trackedProduct.product.price != null
                                        ? <span className="" style={{ "fontWeight": "bold", "cursor": "pointer" }}><span>$</span> {trackedProduct.product.price} </span>
                                        : <span className="" style={{ "fontWeight": "bold", "cursor": "pointer" }}>Price not available</span>
                                    }
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    <span className="badge text-bg-success">{formatStatus(trackedProduct.statusTrackProduct)}</span>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    {/* Jan 12, 2023 3:40 PM  */}
                                    <span className="" style={{ "fontWeight": "bold", "cursor": "pointer" }}>{formatDate(trackedProduct.createdAt)}</span>
                                </div>
                                <div className="col-lg-1 col-md-1">
                                    <div className="dropdown dropstart" style={{ cursor: 'pointer' }}>
                                        <i className="fa-solid fa-ellipsis dropdown-t" alt="" onClick={showDropdown} data-bs-toggle="dropdown" ></i>
                                        <ul className="dropdown-menu" style={{ cursor: 'pointer' }}>
                                            {/* <li> <span className="dropdown-item" >Stop</span> </li> */}
                                            <Link to={encodeURI(`/priceHistory?title=${trackedProduct.product.title}&id=${trackedProduct.id}`)}>
                                                <span className="dropdown-item" >Price History</span>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                }

                {/* Small Devices */}
                {trackedProducts.map((trackedProduct, index) => {
                    return <div className="col-12 col-sm-12 shadow-sm  d-sm-block d-md-none  d-block mt-4" key={index}>
                        <div className="row" style={{ padding: "8px", borderBottom: "1px solid #eeeeee" }}>
                            <div className="col-sm-4 col-4 d-flex justify-content-center">
                                <img
                                    src={trackedProduct.product.urlImage}
                                    className="img-fluid"
                                    style={{ "width": "auto", "height": "140px", "borderRadius": "5px" }}
                                    alt="..." />
                            </div>
                            <div className="col-sm-7 col-7">
                                <h3
                                    className="truncate-multi-line"
                                    style={{ "fontWeight": "bold", "cursor": "pointer", "fontSize": "1.1rem", }} >
                                    {trackedProduct.product.title}
                                </h3>
                                <div>                                    
                                    {trackedProduct.product.price != null
                                        ? <span style={{ "fontWeight": "bold", "cursor": "pointer" }}><span>$</span> {trackedProduct.product.price} </span>
                                        : <span style={{ "fontWeight": "bold", "cursor": "pointer" }}>Price not available</span>
                                    }
                                </div>
                                <div>
                                    <span className="badge text-bg-success">{formatStatus(trackedProduct.statusTrackProduct)}</span>
                                </div>
                                <div>
                                    <span style={{ "fontWeight": "bold", "cursor": "pointer" }}>{formatDate(trackedProduct.createdAt)}</span>
                                </div>

                            </div>
                            <div className="col-sm-1 col-1">
                                <div>
                                    <div className="dropdown dropstart" style={{ cursor: "pointer" }} >
                                        <i className="fa-solid fa-ellipsis dropdown-t"
                                            alt="" onClick={showDropdown} data-bs-toggle="dropdown" style={{ cursor: "pointer" }}></i>
                                        <ul className="dropdown-menu" >
                                            <li>
                                                <Link to={encodeURI(`/priceHistory?title=${trackedProduct.product.title}&id=${trackedProduct.id}`)}>
                                                    <span className="dropdown-item" >Price History</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                })}

                {
                    (trackedProducts.length === 0 && loader == false) &&
                    <div className="d-flex justify-content-center">
                        <p style={{ fontSize: "1.2rem" }}>You haven't added any products to track.</p>
                    </div>
                }


                {trackedProducts.length != 0 &&
                    <Pagination
                        length={totalProductFound}
                        itemPerPage={productsPerPage}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                    />
                }
            </div>

        </div>
    )

}

export default TrackedProductsPage;