import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import Toast from "../components/toast.component";
import toast, { Toaster } from "react-hot-toast";

const notify = (title, message, type) => {
    toast.custom(
        <Toast title={title} message={message} type={type} />, {
        duration: 4000,
    }
    );
}
const ProfilePage = () => {

    const [user, setUser] = useState(null);
    const [loader, setLoader] = useState(false);

    const resendVerification = () => {
        setLoader(true);
        UserService.resendEmailVerification()
            .then(response => response.data)
            .then(data => {
                if (data.status === "NOT_VERIFIED")
                    notify("Verification Email", data.message, "SUCCESS");

                if (data.status === "VERIFIED")
                    notify("Verification Email", data.message, "WARNING");
                setLoader(false);
            })
            .catch(responseError => {
                notify("Verification Email", responseError.data.message, "DANGER");
                setLoader(false);
            })
    }

    useEffect(() => {

        (async () => {
            const user = await UserService.getUser();
            setUser(user);
        })();

    }, []);


    return (
        <div>
            <Toaster />
            <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Profile</h1>
            <form>
                <div className="row mb-4">
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Username</h3>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="username"
                                name="username"
                                value={user != null && user.email}
                                placeholder="Username"
                                disabled
                            />
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className="col-lg-10" style={{ "position": "relative" }}>
                        <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Contact Email</h3>
                        <div className="row">
                            <div className="col-6">
                                <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Manage your accounts email.</h5>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="email"
                                        name="email"
                                        value={user != null && user.email}
                                        placeholder="Example@exmaple.com"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <h5 className="mb-2" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Email Verified.</h5>

                                <div className="row d-flex align-items-center">
                                    <div className="col-12 col-lg-5 col-xl-3">
                                        {
                                            user != null && !user.accountVerified &&
                                            <span className="badge text-bg-warning">Not Verified</span>
                                        }
                                        {
                                            user != null && user.accountVerified &&
                                            <span className="badge text-bg-success">Verified</span>
                                        }
                                    </div>
                                    <div className="col-12 col-lg-7 mt-2">
                                        {
                                            user != null && !user.accountVerified && !loader &&
                                            <span
                                                className="btn btn-info btn-sm"
                                                onClick={() => resendVerification()}
                                            >Resend Verification </span>

                                        }
                                    </div>

                                    {
                                        loader === true &&
                                        <div className="mb-3 ml-3">
                                            <div className="spinner-border text-info" role="status">
                                                <span className="visually-hidden" >Loading...</span>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div className="row  mb-4">

                    <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Password</h3>
                    <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Modify your current password..</h5>
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control form-control-sm"
                                placeholder="New password"
                                disabled

                            />
                        </div>
                    </div>
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control form-control-sm"
                                placeholder="Confirm password"
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div>
                        <button type="submit" disabled
                            className="btn btn-primary btn-sm"
                            style={{ "backgroundColor": 'red !important' }}
                        >Save change</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default ProfilePage;