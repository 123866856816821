import axios from "axios";
import AuthProviderService from "./authProvider.service";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/product`;

const findAllByPage = async (page,sizePerPage,searchId,sortDirection=null, minPrice=null,maxPrice=null) => {
    let url = `${API_URL}/find/${searchId}?page=${page}&sizePerPage=${sizePerPage}`;
    if (sortDirection !==null ) url = url+`&sortDirection=${sortDirection}`;
    if (minPrice !==null && maxPrice !== null) url = url+`&minPrice=${minPrice}&maxPrice=${maxPrice}`;
    return axios.get(url);
}

const findById = async (productId) => {
    return axios.get(`${API_URL}/findById/${productId}`);
}

const ProductService = {
    findAllByPage,
    findById
}

export default ProductService;