import UserService from "./user.service";

const getAuthorizationHeader = () => {
    const isExpired = UserService.isTokenExpired();
    if( !isExpired ){
        const token = UserService.getCurrentUser();
        const headers = { 'Authorization': `Bearer ${token.token}` };
        return headers;
    }
    return null;
}

const AuthProviderService = {
    getAuthorizationHeader
}

export default AuthProviderService;