import React, { useEffect } from "react";
import Header from "../components/header.component";
import { Outlet, useLoaderData } from "react-router-dom";
import Footer from "../components/footer.component";


const Layout = ({ user }) => {
    return (
        <>
            <div
                style={{
                    display: "flex", flexDirection: "column", minHeight: "100vh"
                }}>
                <Header user={user} />
                <div
                    style={{ flex: "1" }}
                    className="ct-outlet">
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )

}

export default Layout;