import React from "react";


const Error = () => {


    return (

        <div>
            Error
        </div>
    )
}

export default Error;