import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import Header from './components/header.component';
import { jwtDecode } from 'jwt-decode' // import dependency
import { BrowserRouter, createBrowserRouter, Router, RouterProvider } from 'react-router-dom';

import VisitorService from './services/visitor.service';
import RegisterPage from './pages/register.page';
import Login from './pages/login.page';
import UserService from './services/user.service';
import Layout from './pages/layout.page';
import Error from './pages/error.page';
import ProtectedRoute from './components/protectedRoute.component';
import HomePage from './pages/home.page';
import HistoryPage from './pages/history.page';
import AccountPage from './pages/account.page';
import ProfilePage from './pages/profile.page';
import TrackedProductsPage from './pages/trackedProducts.page';
import DisplayResult from './pages/displayResult.page';
import ProductSearch from './pages/productSearch.page';
import PriceHistory from './pages/priceHistory.page';
import VerifyAccountPage from './pages/verifyAccount.page';
import GenerateTokenPage from './pages/generateToken.page';
import RedirectPage from './pages/redirect.page';
import Contactus from './pages/contactus.page';


const App = () => {

  const [currentUser, setCurrentUser] = useState(null);
  const [currentVisitor, setCurrentVisitor] = useState(null);



  const getUser = async () => {
    const isExpired = UserService.isTokenExpired();
    if(!isExpired){
      const userToken = UserService.getUserFromToken();
      return { email: userToken.email };
    }
    return null;
  };
  
  useEffect(() =>{
    (async () => {
      
      VisitorService.registerVisitor();
      const user = await getUser();
      setCurrentUser(user);
    })();

  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout user={currentUser} />,
      errorElement: <Error />,
      //loader: async () => { return getUser() },

      children: [
        {
          path: "",
          element: <HomePage />, 
        },
        {
          path: "/search",
          element: <ProductSearch user={currentUser}/>, 
        },
        {
          path: "/register",          
          element: <RegisterPage />
        },
        {
          path: "/login",
          element: <Login />
        },
        {
          path: "/account",
          element: <ProtectedRoute  publicRoute={false} ><AccountPage/></ProtectedRoute>,
          errorElement: <Error />,
          children : [
            {
              path: "/account/profile",
              element: <ProfilePage />
            },
            {
              path: "/account/history",
              element: <HistoryPage />
            },
            {
              path: "/account/trackedProducts",
              element: <TrackedProductsPage />
            },
            // {
            //   path: "/account/displayResult/:id",
            //   element: <DisplayResult />
            // }
          ]
        },
        {
          path:"priceHistory",
          element: <ProtectedRoute publicRoute={false} ><PriceHistory /></ProtectedRoute>
          //  <ProtectedRoute user={currentUser} publicRoute={true} ><PriceHistory /></ProtectedRoute>
        },
        {
          path:"verifyAccount/confirmEmail",
          element: <VerifyAccountPage/>
        },
        {          
          path:"redirect",
          element: <RedirectPage/>
        },
        {
          path:"generateToken",
          element: <GenerateTokenPage/>          
        },
        {
          path: "contactus",
          element: <Contactus />
        }

      ]
    }
  
  ]);

  return (
    <>              
        <RouterProvider router={router} />
    </>
  );
}

export default App;
