import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ProductService from "../services/product.service";

const capitalizeFirstLetter = (website) => {
    return website.charAt(0).toUpperCase() + website.slice(1);
}

const RedirectPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const id = searchParams.get('id');
        if (id != null || id != undefined) {
            ProductService.findById(id)
                .then(response => response.data)
                .then(data => {
                    setProduct(data);
                    setTimeout(() => {
                        if (data.urlProduct != null) {
                            window.location.href = data.urlProduct;                            
                        }
                    }, 3000);
                })
                .catch(responseError => {
                    console.log(responseError);
                });
        } else {
            navigate("/")
        }
    }, []);

    return (
        <div className="container" style={{ "marginTop": "120px" }}>
            {
                product &&
                <div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center">
                                <span className="" style={{color: "#1976d2", fontWeight: "bold", fontSize: "2.1rem" }}>Bubeph</span>
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-1 d-flex justify-content-center">
                                <img src="images/Infinity-1s-40px.gif" alt="" style={{  }} />
                            </div>
                            <div className="col-sm-12 col-md-2 col-lg-2 d-flex justify-content-center">
                                <span style={{fontWeight: "bold", fontSize: "2.1rem" }}>{capitalizeFirstLetter(product.website)}</span>
                            </div>
                    </div>
                    <div className='d-flex justify-content-center mt-4'>
                        <p style={{ fontSize: "1.3rem" }}>
                            You'll never struggle to find the best deal again!
                        </p>
                    </div>
                </div>

            }
        </div>
    )

}

export default RedirectPage;