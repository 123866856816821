import React, { useEffect, useState } from "react"
import { Route, Navigate } from 'react-router-dom';
import Login from "../pages/login.page";
import UserService from "../services/user.service";

const useAuth = () => {
    return true;
}
const ProtectedRoute = ({ children, publicRoute = true }) => {

    const [user, setUser] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const us = await UserService.getUser();
        setUser(us);
    }
    // UserService.getUser().then( user => {
    //     if (publicRoute) {
    //         if (user !== null && user !== undefined) {
    //             return <Navigate to="/" />
    //         }
    //     }
    //     if (!publicRoute) {
    //         if (user == null || user == undefined) {
    //             return <Navigate to="/" />
    //         }
    //     }
    // })

    // return  (data !== null ? 
    //     children : <Navigate to="/" />)
    if (publicRoute) {
        if (user !== null && user !== undefined) {
            return <Navigate to="/" />
        }
    }
    if (!publicRoute) {
        if (user == null || user == undefined) {
            return <Navigate to="/" />
        }
    }
    return children


}


export default ProtectedRoute;
