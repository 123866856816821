import React, { useEffect, useState } from 'react';


const calculateStars = (stars) => {
    const starSolid = Math.floor(stars);
    const rest = stars - starSolid;
    const starHalf = rest < 1 && rest > 0 ? 1 : 0;
    const starRegular = Math.floor(5 - stars);
    return {
        "starSolid": starSolid,
        "starHalf": starHalf,
        "starRegular": starRegular
    }
}

const Stars = ({ stars }) => {
    const [res, setRes] = useState(null);
    useEffect(() => {        
        const res1 = calculateStars(stars);
        setRes(res1);
    }, []);

    return (res != null ? <span>
            {
                [...Array(res.starSolid)].map((x, index) =>
                    <i className="fa-solid fa-star" key={index} style={{ "cursor": "pointer", color: "#fdd835" }}></i>
                )
            }
            {
                [...Array(res.starHalf)].map((x, index) =>
                    <i className="fa-solid fa-star-half-stroke" key={index} style={{ "cursor": "pointer", color: "#616161" }}></i>
                )
            }
            {
                [...Array(res.starRegular)].map((x, index) =>
                    <i className="fa-regular fa-star" key={index} style={{ "cursor": "pointer" }}></i>
                )
            }
        </span>
        : <span></span>)

}

export default Stars;