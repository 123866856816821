import React from "react";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserService from "../services/user.service";
import * as bootstrap from 'bootstrap';
// const { Toast } = bootstrap 
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Toast from "../components/toast.component";



const GenerateTokenPage = () => {

    const notify = (title, message, type) => {
        toast.custom(
            <Toast title={title} message={message} type={type} />, {
            duration: 4000,
        }
        );
    }

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required')
        }),
        onSubmit: async (values) => {
            if (formik.isValid) {
                try {
                    
                    const generateTokenRespoonse = await UserService.generateToken(values);
                    const data = generateTokenRespoonse.data;
                    notify("Confirmation Email", data.message, "WARNING");
                    // navigate("/");
                    // navigate(0)
                } catch (errorResponse) {
                    console.error(errorResponse);
                    //if (errorResponse.response.status === 403) {
                    //    notify("Sign in", "Please check your email or password and try again.", "WARNING");
                    //}
                }
                // store token, in local storage; 
                // handle 403 
                // redirect to home page 

            }
        },
    });


    return (
        <div className="container-fluid">
            <Toaster />
            <div className="row d-flex justify-content-center">
                <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5 col-8 shadow-sm" style={{ "marginTop": "150px", "padding": "13px" }}>
                    <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Welcome</h3>
                    <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Please enter your email to regenerate your token</h5>

                    <form onSubmit={formik.handleSubmit}>

                        <div className="" style={{ "position": "relative" }}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="email"
                                    name="email"
                                    placeholder="Example@exmaple.com"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.email}</div>
                                ) : null}
                                {/* {messageErrorEmail != "" ? (
                  <div className="mt-1" style={{ "color": "#b71c1c", "fontSize":"0.8rem", "fontWeight": "bold" }}>{messageErrorEmail}</div>
                ) : null} */}
                            </div>                           

                            <div >
                                <button type="submit" className="btn btn-primary btn-sm">generate</button>
                            </div>
                        </div>

                    </form>

                    <div className="d-flex justify-content-center mt-4">
                        <p style={{ fontSize: "0.9rem", fontWeight: "bold" }}> Don't have an account yet?  <Link to="/register" style={{ color: "#1976d2" }}>Sign up</Link>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenerateTokenPage;