import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';
import UserService from '../services/user.service';
import NotificationService from '../services/notification.service';
import { formatDistanceToNow } from 'date-fns';

const showDropdown = (e) => {
  if (e.target != null && e.target != undefined) {
    const dropdown = new bootstrap.Dropdown(e.target);
    dropdown.show()
  }
}

const timeAgo = (date) => {
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });
  return timeAgo;
}

const close = () => {
  const closeEement = document.querySelector(".close-notifications");
  closeEement.click()

}
const Header = ({ user }) => {

  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [notificationsPerPage, setNotificationsPerPage] = useState(4);
  const [totalNotificationFound, setTotalNotificationFound] = useState(null);
  const [countUnreadNotifications, setCountUnreadNotifications] = useState(null);
  const [loader, setLoader] = useState(true);
  const scrollContainerRef = useRef(null);

  const loadMore = (e) => {
    const scrollElement = scrollContainerRef.current;
    const isEnd = scrollElement.scrollTop + scrollElement.clientHeight >= scrollElement.scrollHeight;

    // not send request when total of notification is equal
    const numberOfpages = Math.ceil(totalNotificationFound / notificationsPerPage);
    if (isEnd && page <= numberOfpages) {
      setLoader(true);
      setPage(page + 1);
      NotificationService.findAllByPage(page, notificationsPerPage)
        .then(response => response.data)
        .then(data => {
          setNotifications((prevNotifications) => [...prevNotifications, ...data.content]);
          setTotalNotificationFound(data.totalElements);
        })
        .catch(errorResponse => console.error(errorResponse));
      setLoader(false);
    }
  };

  const markAsRead = (id) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification => {
        if (notification.id === id) {
          if (!notification.read) setCountUnreadNotifications(countUnreadNotifications - 1);
          notification.read = true;
          const updatedNotification = notification;
          return { ...notification, ...updatedNotification };
        }
        return notification
      }
      )
    );
  }

  const markAllAsRead = () => {
    NotificationService.markAllNotificationAsRead()
      .then(response => {
        if (response.data) {
          setNotifications(prevNotifications =>
            prevNotifications.map(notification => {
              /* if (notification.id === id) {
                if (!notification.read) setCountUnreadNotifications(countUnreadNotifications - 1);
                notification.read = true;
                const updatedNotification = notification;
                return { ...notification, ...updatedNotification };
              } */
              notification.read = true;
              return notification
            }
            )
          );
        }
      })
      .catch(responseError => console.error(responseError));

  }

  useEffect(() => {
    const offcanvasElementList = document.querySelectorAll('.offcanvas')
    const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new bootstrap.Offcanvas(offcanvasEl));
    // check if user is authenticated 

    if (!UserService.isTokenExpired()) {

      NotificationService.countUnreadNotifications()
        .then(response => setCountUnreadNotifications(response.data))
        .catch(ressponseError => console.log(ressponseError));

      NotificationService.findAllByPage(page, notificationsPerPage)
        .then(response => response.data)
        .then(data => {
          setNotifications(data.content);
          setTotalNotificationFound(data.totalElements);
          setPage(page + 1);
          setLoader(false);
        })
        .catch(errorResponse => console.error(errorResponse));
    }

    // 
    setInterval(() => {
      NotificationService.countUnreadNotifications()
        .then(response => {
          if (response.data != 0) {
            setCountUnreadNotifications(response.data);
            setPage(1);
            NotificationService.findAllByPage(page, notificationsPerPage)
              .then(response => response.data)
              .then(data => {
                setNotifications(data.content);
                setTotalNotificationFound(data.totalElements);
                setPage(page + 1);
                setLoader(false);
              })
              .catch(errorResponse => console.error(errorResponse));
          }
        })
        .catch(ressponseError => console.log(ressponseError));
    }, 35 * 60 * 1000); // 35 min
  }, []);


  const logout = () => {
    UserService.logout();
    navigate("/");
    window.location.reload(true);
  }

  const hideSideMenu = () => {
    const btnClose = document.querySelector('.btn-close');
    btnClose.click()
  }

  return (
    <>

      <nav className="navbar navbar-expand-lg fixed-top bg-light shadow-sm">
        <div className="container-fluid">
          <Link to="/">
            <span className="navbar-brand" style={{ color: "#1976d2" }} >Bubeph</span>
          </Link>

          <div className='d-flex d-xxl-none d-xxl-block d-xl-none d-lg-none '>
            <div className='mt-2 mr-2' style={{ marginLeft: "30px" }}>
              {countUnreadNotifications != null && countUnreadNotifications == 0 &&
                <i
                  className="fa-regular fa-bell fa-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                ></i>
              }
              {countUnreadNotifications != null && countUnreadNotifications != 0 &&
                <i
                  className="fa-solid fa-bell fa-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ color: "#e57373" }}
                ></i>
              }
            </div>

            <div className='ml-2'>
              <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          {/* Start Side menu on small devices */}
          <div className="offcanvas offcanvas-end d-lg-none d-xl-none d-xxl-none" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Menu</h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/">
                    <span className="dropdown-item" onClick={() => hideSideMenu()} >Home</span>
                  </Link>
                </li>
              </ul>
              <span className="navbar-text">

                {
                  user === null || user === undefined ?
                    <>
                      <button className="btn btn-primary btn-sm" onClick={() => hideSideMenu()}><Link to="/register" style={{ color: "white" }}>Create Account</Link></button>
                      <button className="btn btn-success btn-sm ml-3" onClick={() => hideSideMenu()}> <Link to="/login" style={{ color: "white" }}>Log in</Link></button>
                    </>
                    :
                    <ul>
                      <li style={{ cursor: "pointer" }} className='d-flex flex-row'>
                        <div className="dropdown">
                          <ul className="">
                            <li>
                              <Link to="/account/profile">
                                <span className="" onClick={() => hideSideMenu()}>Profile</span>
                              </Link>
                            </li>
                            <li className='mt-2'>
                              <Link to="/account/history">
                                <span className="" onClick={() => hideSideMenu()}>History</span>
                              </Link>
                            </li>
                            <li className='mt-2'>
                              <Link to="/account/trackedProducts">
                                <span className="" onClick={() => hideSideMenu()}>Tracked products</span>
                              </Link>
                            </li>
                            <li className='mt-2'>
                              <a className="" onClick={() => logout()} >Log out</a>
                            </li>
                          </ul>
                        </div>

                      </li>
                    </ul>

                }
              </span>
            </div>
          </div>
          {/* End Side menu on small devices */}

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/">
                  <span className="dropdown-item" >Home</span>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">
              {
                user === null || user === undefined ?
                  <>
                    <button className="btn btn-primary btn-sm"><Link to="/register" style={{ color: "white" }}>Create Account</Link></button>
                    <button className="btn btn-success btn-sm ml-3"> <Link to="/login" style={{ color: "white" }}>Log in</Link></button>
                  </>
                  :
                  <ul className='d-flex flex-row'>
                    <li className='mr-2' style={{ cursor: "pointer" }}>
                      {/* fa-regular */}
                      <div className="dropdown dropstart">
                        {countUnreadNotifications != null && countUnreadNotifications == 0 &&
                          <i
                            className="fa-regular fa-bell fa-lg dropdown notification-dropdown"
                            data-bs-toggle="dropdown"
                            onClick={showDropdown}
                          ></i>
                        }
                        {countUnreadNotifications != null && countUnreadNotifications != 0 &&
                          <i
                            className="fa-solid fa-bell fa-lg dropdown notification-dropdown"
                            data-bs-toggle="dropdown"
                            onClick={showDropdown}
                            style={{ color: "#e57373" }}
                          ></i>
                        }
                        {/* maxHeight: "350px" */}
                        <ul className="dropdown-menu"
                          style={{ width: "400px", marginTop: "20px", maxHeight: "350px", overflowY: "scroll" }}
                          onScroll={e => loadMore(e)}
                          ref={scrollContainerRef}
                        >
                          <li className='d-flex  justify-content-between' style={{ padding: "8px" }}>
                            <span style={{ fontSize: "1.09rem", fontWeight: "bold" }}>Notifications </span>
                            <span style={{ color: "#1976d2" }} onClick={markAllAsRead}> <i className="fa-solid fa-check-double"></i> </span>
                          </li>
                          {
                            notifications.length === 0 &&
                            <li style={{ padding: "8px" }}>
                              <p style={{ fontSize: "0.98rem" }}> No notifications to display at the moment. </p>
                            </li>
                          }
                          {notifications.map((notification, index) => {
                            return <li key={index} style={{ borderTop: "1px solid #e0e0e0" }}>
                              <Link to={encodeURI(`/priceHistory?title=${notification.product.title}&id=${notification.trackedProductId}&notification=${notification.id}`)}>
                                <div
                                  className='d-flex'
                                  style={{ padding: "8px", backgroundColor: !notification.read ? 'aliceblue' : '' }}
                                  onClick={() => markAsRead(notification.id)}
                                >
                                  <div 
                                    className='d-flex d-flex justify-content-center align-items-center' 
                                    style={{ width:"19%", height:"75px"}}>
                                    <img  style={{ height:"100%"}} src={notification.product.urlImage} alt="" /> 
                                  </div>
                                  <div className='ml-3'
                                    style={{ width:"81%" }}>                                    
                                    <p style={{ fontSize: "0.87rem", fontWeight: "revert" }}>{notification.message} </p>
                                    <p>
                                      <strong><small> {timeAgo(notification.createdAt)} </small></strong>
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          })}
                          {
                            loader &&
                            <div className='d-flex justify-content-center mt-3' >
                              <div className="spinner-border text-info" role="status">
                                <span className="visually-hidden" >Loading...</span>
                              </div>
                            </div>
                          }

                        </ul>
                      </div>
                    </li>
                    <li style={{ cursor: "pointer" }} className='d-flex flex-row ml-3'>
                      <div className="dropdown dropstart">
                        <img src="/images/user_1.png" className="dropdown-t" alt="" height="25px" width="25px" onClick={showDropdown} data-bs-toggle="dropdown" />
                        <ul className="dropdown-menu">
                          <li>
                            <Link to="/account/profile">
                              <span className="dropdown-item" >Profile</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/account/history">
                              <span className="dropdown-item" >History</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/account/trackedProducts">
                              <span className="dropdown-item" >Tracked products</span>
                            </Link>
                          </li>
                          <li><hr className="dropdown-divider" /></li>
                          <li>
                            <a className="dropdown-item" href="#" onClick={() => logout()}>Log out</a>
                          </li>
                        </ul>
                      </div>

                    </li>
                  </ul>

              }
            </span>
          </div>
        </div>

      </nav>


      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={{ fontSize: "1.09rem", fontWeight: "bold" }}>Notifications</h5>

              <div>
                <span style={{ color: "#1976d2", marginRight: "13px" }} onClick={markAllAsRead}> <i className="fa-solid fa-check-double"></i> </span>
                <span data-bs-dismiss="modal" aria-label="Close" className='close-notifications'><i className="fa-solid fa-xmark"></i></span>
              </div>
            </div>
            <div className="modal-body"
              style={{ padding: "0px", maxHeight: "300px", maxHeight: "350px", overflowY: "scroll" }}
              onScroll={e => loadMore(e)}
              ref={scrollContainerRef}>

              {
                notifications.length === 0 &&
                <div className='d-flex justify-content-between align-items-center'>
                  <div style={{ padding: "8px" }}>
                    <p style={{ fontSize: "0.98rem" }}> No notifications to display at the moment. </p>
                  </div>
                </div>
              }

              {notifications.map((notification, index) => {
                return <div key={index}
                  style={{ paddingLeft: "7px", paddingRight: "7px", borderBottom: "1px solid #e0e0e0", backgroundColor: !notification.read ? 'aliceblue' : '' }}
                  onClick={() => { markAsRead(notification.id); close() }}

                >
                  <Link to={encodeURI(`/priceHistory?title=${notification.product.title}&id=${notification.trackedProductId}&notification=${notification.id}`)}>
                    <div className='d-flex justify-content-between align-items-center p-1'>
                      <div 
                        className='d-flex justify-content-between align-items-center' 
                        style={{ width:"19%",  height:"67px"}}>
                        <img style={{ height: "100%" }} src={notification.product.urlImage} alt="" />
                      </div>

                      <div style={{ width:"81%", padding: "4px", marginLeft: "2px", }}>
                        <p style={{ fontSize: "0.87rem", fontWeight: "revert" }}>{notification.message} </p>
                        <strong><small> {timeAgo(notification.createdAt)} </small></strong>
                      </div>

                    </div>
                  </Link>
                </div>
              })}

              {
                loader &&
                <div className='d-flex justify-content-center mt-3' >
                  <div className="spinner-border text-info" role="status">
                    <span className="visually-hidden" >Loading...</span>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Header;