import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/contact`;

const save = (contactForm) => {
    return axios.post(`${API_URL}/save`, contactForm);
}

const ContactService = {
    save
}

export default ContactService;