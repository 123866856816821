import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import Stars from '../components/stars.component';
import { useLocation } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TrackingProductService from '../services/trackingProduct.service';
import NotificationService from '../services/notification.service';

let chart = null;
let chart2 = null;

const constructConfig = (labels, prices, callback, label) => {
    if (chart != null) chart.destroy();
    if (chart2 != null) chart2.destroy();
    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
    const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;
    const trick = (callback === null) ? { display: true } : { callback: (value, index) => { return callback(value, index, labels) } };

    const config = {
        type: 'line',
        data: {
            labels: labels,
            datasets: [
                {
                    data: prices,
                    label: label,
                    borderColor: "#3e95cd",
                    fill: true,
                    segment: {
                        // borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
                        borderDash: ctx => skipped(ctx, [6, 6]),
                    },
                    spanGaps: true,
                }
            ]
        },
        options: {

            responsive: true,
            title: {
                display: true,
                text: 'World population per region (in millions)'
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: trick
                },
                y: {
                    grid: {
                        display: true
                    }
                }
            }
        }
    };


    // (async() => {        
    //     const lineChart = document.getElementById("line-chart-1");
    //     const lineChartSmallDevices = await document.getElementById("line-chart-2");

    //     chart = new Chart(lineChart, config);
    //     chart2 = new Chart(lineChartSmallDevices, config);
    //     chart.update();
    // })();    
    //return chart;

    const lineChart = document.getElementById("line-chart-1");
    const lineChartSmallDevices = document.getElementById("line-chart-2");
    chart = new Chart(lineChart, config);
    chart2 = new Chart(lineChartSmallDevices, config);
    chart.update();
}

const PriceHistory = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [timePeriod, setTimePeriod] = useState("1W");
    const [product, setProduct] = useState(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [avgPrice, setAvgPrice] = useState(null);
    const [loader, setLoader] = useState(true);

    const createChartWithStat = (priceHistories, product) => {
        let labels = [];
        let prices = [];
        let map = new Map();
        const optionsDate = { month: 'short', day: '2-digit' }; //  year: 'numeric'                
        if (priceHistories.length === 0) {
            const onePriceHostory = {
                productId: product.id,
                newPrice: product.price,
                oldPrice: product.price,
                createdAt: product.insertedAt
            }
            priceHistories.push(onePriceHostory);
        }
        const lastData = priceHistories[priceHistories.length - 1];
        let callback = null;
        let length = 0;
        if (timePeriod === "1W") {
            length = 7;
        } else if (timePeriod === "1M") {
            length = 30;
            callback = (value, index, labels) => { return (index + 1) % 3 === 0 ? labels[index] : '' }
        } else if (timePeriod === "6M") {
            length = 6 * 30;
            callback = (value, index, labels) => { return (index + 1) % 3 === 0 ? labels[index] : '' }
        } else if (timePeriod === "1Y") {
            length = 12 * 30;
            callback = (value, index, labels) => { return (index + 1) % 3 === 0 ? labels[index] : '' }
        }

        // map date with their price
        priceHistories.forEach(ele => {
            let date = new Date(ele.createdAt);
            let formattedDateAsKey = date.toLocaleDateString('en-US', optionsDate);
            map.set(formattedDateAsKey, ele.newPrice);
        });
        const arrIndexes = Array.from({ length: length }, (v, i) => i).reverse();
        arrIndexes.forEach(ele => {
            let previousDate = new Date(lastData.createdAt);
            previousDate.setDate(previousDate.getDate() - ele);
            let formattedDate = previousDate.toLocaleDateString('en-US', optionsDate);
            labels.push(formattedDate);

        });
        labels.forEach((label) => {
            if (map.has(label)) {
                prices.push(map.get(label));
            } else {
                prices.push(NaN);
            }
        });
        // 
        const validPrices = prices.filter(value => !isNaN(value) && value != null);
        const sum = validPrices.reduce((acc, value) => acc + value, 0);
        const avgValue = validPrices.length > 0 ? sum / validPrices.length : NaN;
        setMinPrice(Math.min(...validPrices));
        setMaxPrice(Math.max(...validPrices));
        setAvgPrice(avgValue.toFixed(2));
        const label = `${product.title.slice(0, 30)}...`;

        const config = constructConfig(labels, prices, callback, label);
        // if (chart != null) chart.destroy();
        // if (chart2 != null) chart2.destroy();
        // const lineChart = document.getElementById("line-chart-1");
        // const lineChartSmallDevices = document.getElementById("line-chart-2");
        // console.log(lineChart)
        // const observer = new MutationObserver((mutations) => {
        //     if (lineChartSmallDevices) {
        //         chart2 = new Chart(lineChartSmallDevices, config);
        //         chart2.update();
        //         observer.disconnect();
        //     }
        //     if (lineChart) {
        //         chart = new Chart(lineChart, config);
        //         console.log(lineChart)
        //         chart.update();
        //         observer.disconnect();
        //     }
        // });
        // observer.observe(document.body, { childList: true, subtree: true });
        // chart2 = new Chart(lineChartSmallDevices, config);
        // //chart2.update();
        // chart = new Chart(lineChart, config);
        //chart.update();        
    }

    const findTrackedProduct = (id) => {
        TrackingProductService.findTrackedProduct(id)
        .then((response) => { return response.data })
        .then((data) => {
            setProduct(data.product);
            createChartWithStat(data.priceHistories, data.product);
            setLoader(false);
        })
        .catch((errorResponse) => {                                        
            console.error(errorResponse);
        });
    }

    useEffect(() => {
        const notification = searchParams.get('notification');
        if (notification != null && notification != undefined) {
            NotificationService.markNotificationAsRead(notification);
            const id = searchParams.get('id');
            if(id != null){
                findTrackedProduct(id);
            }
        }
    }, [location]);

    useEffect(() => {        
        const id = searchParams.get('id');
        if(id != null){
            findTrackedProduct(id);
        }
    }, [timePeriod]);

    return (
        <div className="container-fluid" style={{ "marginTop": "120px" }}>

            {
                (loader === true && product == null) &&
                <div className='row d-flex justify-content-center mt-5' >
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden" >Loading...</span>
                    </div>
                </div>
            }

            {/* Start Display On lg, xl and xxl */}

            <div className='d-none d-lg-block d-xl-block d-xxl-block'>
                <div className='row' style={{ marginTop: "100px" }}>
                    <div className="col-lg-8 col-xl-9 col-xxl-9">
                        <div className="row">
                            <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Overview</h1>
                            <div className="col-lg-12 col-xl-9 col-xxl-10 order-lg-2 order-xl-1 shadow-sm p-2">
                                <div className='d-flex justify-content-between'>
                                    {product && <div style={{ position: "relative" }}>
                                        <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                        <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{product.price}</span>
                                    </div>}

                                    <div>
                                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <span
                                                    className="nav-link active"
                                                    onClick={() => setTimePeriod("1W")}
                                                    style={{ cursor: "pointer" }}
                                                    id="pills-1W-tab" data-bs-toggle="pill" data-bs-target="#pills-1W" role="tab" aria-controls="pills-1W" aria-selected="true"
                                                >1 Week</span>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <span
                                                    className="nav-link"
                                                    onClick={() => setTimePeriod("1M")}
                                                    style={{ cursor: "pointer" }}
                                                    id="pills-1M-tab" data-bs-toggle="pill" data-bs-target="#pills-1M" role="tab" aria-controls="pills-1M" aria-selected="true"
                                                >1 Month</span>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <span
                                                    className="nav-link"
                                                    onClick={() => setTimePeriod("6M")}
                                                    style={{ cursor: "pointer" }}
                                                    id="pills-6M-tab" data-bs-toggle="pill" data-bs-target="#pills-6M" role="tab" aria-controls="pills-6M" aria-selected="true"
                                                >6 Month</span>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <span
                                                    className="nav-link"
                                                    onClick={() => setTimePeriod("1Y")}
                                                    style={{ cursor: "pointer" }}
                                                    id="pills-1Y-tab" data-bs-toggle="pill" data-bs-target="#pills-1Y" role="tab" aria-controls="pills-1Y" aria-selected="true"
                                                >1 Year</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='p-3'>
                                    <div className="">
                                        {/* style={{ width: "100px", height: "130px" }}  */}
                                        <canvas id="line-chart-1" ></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-3 col-xxl-2 order-lg-1 order-xl-2">

                                {/* Start visible on lg devices */}
                                <div className='d-none d-lg-block d-xl-none mb-3'>
                                    <div className='d-flex'>
                                        <div className='shadow-sm p-2 mt-3'>
                                            <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Avg price</h2>
                                            <div style={{ position: "relative" }} className='mt-2'>
                                                <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                                <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{avgPrice}</span>
                                            </div>
                                        </div>
                                        <div className='shadow-sm p-2 mt-3 ml-2'>
                                            <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Min price</h2>
                                            <div style={{ position: "relative" }} className='mt-2'>
                                                <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                                <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{minPrice}</span>
                                            </div>
                                        </div>
                                        <div className='shadow-sm p-2 mt-3 ml-2'>
                                            <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Max price</h2>
                                            <div style={{ position: "relative" }} className='mt-2'>
                                                <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                                <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{maxPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End visible on lg devices */}

                                {/* Start visible on xl and xxl devices */}
                                <div className='d-lg-none d-xl-block'>
                                    <div className='shadow-sm p-2 mt-3'>
                                        <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Avg price</h2>
                                        <div style={{ position: "relative" }} className='mt-2'>
                                            <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                            <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{avgPrice}</span>
                                        </div>
                                    </div>
                                    <div className='shadow-sm p-2 mt-3 ml-2'>
                                        <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Min price</h2>
                                        <div style={{ position: "relative" }} className='mt-2'>
                                            <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                            <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{minPrice}</span>
                                        </div>
                                    </div>
                                    <div className='shadow-sm p-2 mt-3 ml-2'>
                                        <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Max price</h2>
                                        <div style={{ position: "relative" }} className='mt-2'>
                                            <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                            <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{maxPrice}</span>
                                        </div>
                                    </div>
                                </div>
                                {/* End visible on xl and xxl devices */}


                                {/* Avg price
                                * min price
                                * max price
                                * total search  */}
                            </div>
                        </div>
                    </div>
                    {/* Start Product Information */}
                    {product && <div className="col-lg-4 col-xl-3 col-xxl-3">
                        <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Information of product</h1>
                        <div className="col-lg-12" style={{}}>
                            <div className='shadow-sm' style={{ padding: "20px" }}>
                                <div className='d-flex justify-content-center' style={{ height: "218px", }}>
                                    <img src={product.urlImage} alt=""
                                        style={{ height: "100%", width: "auto" }} />
                                </div>
                                <div className='mt-4'>
                                    <h1 style={{ fontSize: "1.2rem" }}>{product.title}</h1>
                                </div>
                                <div className='mt-3'>
                                    <Stars stars={product.stars} />
                                </div>
                                <div className='mt-3'>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                        onClick={() => { window.open(encodeURI(`/redirect?title=${product.title}&id=${product.id}`), '_blank') }}
                                    >View Product</button>
                                </div>

                            </div>
                        </div>
                    </div>}
                    {/* End Product Information */}
                </div>
            </div>

            {/* End Display On lg, xl and xxl */}


            {/* Start Display On xm, sm and md */}

            <div className='d-block d-sm-block d-md-block  d-xxl-none d-xl-none d-lg-none'>
                <div className='row' style={{ marginTop: "100px" }}>
                    <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Overview</h1>
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-4 shadow-sm p-2 mt-3' style={{ width: "fit-content" }}>
                                <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Avg price</h2>
                                <div style={{ position: "relative" }} className='mt-2'>
                                    <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                    <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{avgPrice}</span>
                                </div>
                            </div>
                            <div className='col-4 shadow-sm p-2 mt-3 ml-2' style={{ width: "fit-content" }}>
                                <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Min price</h2>
                                <div style={{ position: "relative" }} className='mt-2'>
                                    <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                    <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{minPrice}</span>
                                </div>
                            </div>
                            <div className='col-4 shadow-sm p-2 mt-3 ml-2' style={{ width: "fit-content" }}>
                                <h2 style={{ fontSize: "1.08rem", fontWeight: "500" }}>Max price</h2>
                                <div style={{ position: "relative" }} className='mt-2'>
                                    <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                    <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{maxPrice}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 shadow-sm p-2 mt-3">
                        {/* d-flex justify-content-between */}
                        <div className='d-flex flex-row-reverse'>
                            <ul className="nav nav-pills" id="pills-sm-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <span
                                        className="nav-link active"
                                        onClick={() => setTimePeriod("1W")}
                                        style={{ cursor: "pointer" }}
                                        id="pills-1W-tab" data-bs-toggle="pill" data-bs-target="#pills-1W" role="tab" aria-controls="pills-1W" aria-selected="true"
                                    >1 Week</span>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <span
                                        className="nav-link"
                                        onClick={() => setTimePeriod("1M")}
                                        style={{ cursor: "pointer" }}
                                        id="pills-1M-tab" data-bs-toggle="pill" data-bs-target="#pills-1M" role="tab" aria-controls="pills-1M" aria-selected="true"
                                    >1 Month</span>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <span
                                        className="nav-link"
                                        onClick={() => setTimePeriod("6M")}
                                        style={{ cursor: "pointer" }}
                                        id="pills-6M-tab" data-bs-toggle="pill" data-bs-target="#pills-6M" role="tab" aria-controls="pills-6M" aria-selected="true"
                                    >6 Month</span>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <span
                                        className="nav-link"
                                        onClick={() => setTimePeriod("1Y")}
                                        style={{ cursor: "pointer" }}
                                        id="pills-1Y-tab" data-bs-toggle="pill" data-bs-target="#pills-1Y" role="tab" aria-controls="pills-1Y" aria-selected="true"
                                    >1 Year</span>
                                </li>
                            </ul>

                        </div>
                        <div className=''>
                            {product && <div style={{ position: "relative" }}>
                                <span style={{ position: "absolute", fontSize: "1.2rem", fontWeight: "500" }}>$</span>
                                <span style={{ fontSize: "2.2rem", marginLeft: "15px", fontWeight: "500" }}>{product.price}</span>
                            </div>}
                        </div>
                        <div className='p-3'>
                            <div className="">
                                <canvas className="line-chart" id="line-chart-2" style={{ width: "100px" }} > </canvas>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mt-4">
                    <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Information of product</h1>
                    {product && <div className="col-12">
                        <div className='shadow-sm' style={{ padding: "20px" }}>
                            {/* backgroundColor:"#f5f5f5", padding:"8px", border:"1px solid #bdbdbd"    */}
                            <div className='d-flex justify-content-center' style={{ height: "218px", }}>
                                <img src={product.urlImage} alt=""
                                    style={{ height: "100%", width: "auto" }} />
                            </div>
                            <div className='mt-4'>
                                <h1 style={{ fontSize: "1.2rem" }}>{product.title}</h1>
                            </div>
                            <div className='mt-3'>
                                <Stars stars={product.stars} />
                            </div>
                            <div className='mt-3'>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => { window.open(encodeURI(`/redirect?title=${product.title}&id=${product.id}`), '_blank') }}>View Product</button>
                            </div>

                        </div>
                    </div>}
                </div>
            </div>


            {/* End Display On xm, sm and md */}
        </div>


    );
}

export default PriceHistory;