import React from "react";

const Pagination = ({ itemPerPage, length, currentPage, handlePagination }) => {

    const paginationNumbers = [];
    for (let i = 1; i <= Math.ceil(length / itemPerPage); i++) {
        paginationNumbers.push(i);
    }
    const handleClick = (pageNumber) => {
        handlePagination(pageNumber)
    }

    return (
        <div>
            <nav 
                className='d-flex justify-content-center mb-5 mt-4'
                aria-label="..."
                style={{ cursor: "pointer"}}>
                {/* Start 4 < page  <= 5  */}
                <div>
                    {1 < paginationNumbers.length && (paginationNumbers.length < 5 || paginationNumbers.length == 5) &&
                        <ul className="pagination">

                            <li className={`page-item  ${currentPage === 1 ? "disabled" : ""} `}>
                                <a className="page-link" onClick={() => handleClick(currentPage - 1)} >Previous</a>
                            </li>
                            {paginationNumbers.map((pageNumber) => (
                                currentPage === pageNumber ?
                                    <li className="page-item active" aria-current="page" key={pageNumber}>
                                        <span className="page-link" onClick={() => handleClick(pageNumber)} >{pageNumber}</span>
                                    </li>
                                    :
                                    <li className="page-item" key={pageNumber} onClick={() => handleClick(pageNumber)}>
                                        <a className="page-link" >{pageNumber}</a>
                                    </li>

                            ))}
                            <li className={`page-item  ${currentPage === paginationNumbers.length ? "disabled" : ""} `}>
                                <a className="page-link" onClick={() => handleClick(currentPage + 1)}>Next</a>
                            </li>
                        </ul>
                    }
                </div>

                <div>
                    {5 < paginationNumbers.length &&

                        <div>

                            {(currentPage < 2 || currentPage == 2) &&

                                <ul className="pagination">
                                    <li className={`page-item  ${currentPage === 1 ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage - 1)} >Previous</a>
                                    </li>
                                    {[1, 2, 3].map((pageNumber) => (
                                        <li
                                            className={`page-item  ${currentPage === pageNumber ? "active" : ""} `}
                                            aria-current="page" key={pageNumber}>
                                            <span className="page-link" onClick={() => handleClick(pageNumber)} >{pageNumber}</span>
                                        </li>
                                    ))}
                                    <li className="page-item" aria-current="page">
                                        <span className="page-link" >...</span>
                                    </li>

                                    <li
                                        className={`page-item  ${currentPage === paginationNumbers.length ? "disabled" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(paginationNumbers.length)} >{paginationNumbers.length}</span>
                                    </li>

                                    <li className={`page-item  ${currentPage === paginationNumbers.length ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage + 1)}>Next</a>
                                    </li>
                                </ul>

                            }
                            {
                                (currentPage > 3 || currentPage == 3) &&
                                (paginationNumbers.length - currentPage > 3 || paginationNumbers.length - currentPage == 3) &&
                                <ul className="pagination flex-wrap">
                                    <li className={`page-item  ${currentPage === 1 ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage - 1)} >Previous</a>
                                    </li>


                                    <li
                                        className={`page-item  ${currentPage === 1 ? "active" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(1)} >1</span>
                                    </li>

                                    <li className="page-item" aria-current="page">
                                        <span className="page-link" >..</span>
                                    </li>

                                    <li
                                        className={`page-item  ${currentPage === currentPage - 1 ? "active" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(currentPage - 1)} >{currentPage - 1}</span>
                                    </li>

                                    <li
                                        className={`page-item  ${currentPage === currentPage ? "active" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(currentPage)} >{currentPage}</span>
                                    </li>

                                    <li
                                        className={`page-item  ${currentPage === currentPage + 1 ? "active" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(currentPage + 1)} >{currentPage + 1}</span>
                                    </li>

                                    <li className="page-item" aria-current="page">
                                        <span className="page-link" >..</span>
                                    </li>

                                    <li
                                        className={`page-item  ${currentPage === paginationNumbers.length ? "active" : ""} `}
                                        aria-current="page">
                                        <span className="page-link" onClick={() => handleClick(paginationNumbers.length)} >{paginationNumbers.length}</span>
                                    </li>

                                    <li className={`page-item  ${currentPage === paginationNumbers.length ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage + 1)}>Next</a>
                                    </li>
                                </ul>
                            }

                            {
                                paginationNumbers.length - currentPage < 3 &&

                                <ul className="pagination">
                                    <li className={`page-item  ${currentPage === 1 ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage - 1)} >Previous</a>
                                    </li>
                                    <li className={`page-item`}>
                                        <a className="page-link" href="#" onClick={() => handleClick(1)}>1</a>
                                    </li>

                                    <li className="page-item">
                                        <a className="page-link" href="#" >....</a>
                                    </li>

                                    <li className={`page-item  ${currentPage === paginationNumbers.length - 2 ? "active" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(paginationNumbers.length - 2)}>{paginationNumbers.length - 2}</a>
                                    </li>

                                    <li className={`page-item  ${currentPage === paginationNumbers.length - 1 ? "active" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(paginationNumbers.length - 1)}>{paginationNumbers.length - 1}</a>
                                    </li>

                                    <li className={`page-item  ${currentPage === paginationNumbers.length ? "active" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(paginationNumbers.length)}>{paginationNumbers.length}</a>
                                    </li>


                                    <li className={`page-item  ${currentPage === paginationNumbers.length ? "disabled" : ""} `}>
                                        <a className="page-link" href="#" onClick={() => handleClick(currentPage + 1)}>Next</a>
                                    </li>
                                </ul>
                            }
                        </div>

                    }

                </div>


            </nav>

        </div>


    );
};

export default Pagination;