import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import UserService from "../services/user.service";;

const VerifyAccountPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [loader, setLoader] = useState(true);
    const [response, setResponse] = useState(null);


    useEffect(() => {
        const token = searchParams.get("token");
        UserService.confirmEmail(token)
            .then(response => response.data)
            .then(data => {
                console.log(data)
                setResponse(data);
                setLoader(false);
            })
            .catch(responseError => { console.log(responseError) })
        console.log(token);
    }, []);

    return (
        <div className="container" style={{ "marginTop": "120px" }}>

            <div className='row d-flex justify-content-center' >
                <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                    <h2 style={{ fontSize: "1.7rem", fontWeight: "bold" }}>Verfiy Email </h2>
                    <div className="mt-3">
                        {response != null &&
                            <>
                                <p> {response.message} </p>
                                <p>
                                    {(() => {
                                        switch (response.status) {
                                            case 'TOKEN_VALID':
                                                return <Link to="/login">You can now <span 
                                                 style={{ fontSize: "0.9rem", fontWeight: "bold",  color: "#1976d2",  }} >log in.</span> </Link>
                                            
                                            case 'USER_NOT_FOUND':
                                                return <Link to="/register">Please consider creating an account <span 
                                                 style={{ fontSize: "0.9rem", fontWeight: "bold",  color: "#1976d2",  }} >sign up.</span> </Link>
                                            
                                            case 'TOKEN_NOT_VALID':
                                                return <Link to="/generateToken">Click here to resend the <span 
                                                 style={{ fontSize: "0.9rem", fontWeight: "bold",  color: "#1976d2",  }} >verification email.</span> </Link>
                                            default:
                                                return null
                                        }
                                    })()}
                                </p>
                            </>


                        }
                    </div>


                </div>
            </div>
            {
                loader === true &&
                <div className='row d-flex justify-content-center mt-5' >
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden" >Loading...</span>
                    </div>
                </div>
            }

        </div>
    )
}


export default VerifyAccountPage;