import React, { useState, useRef, useEffect } from "react";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import UserService from "../services/user.service";

import * as bootstrap from 'bootstrap';
import { useNavigate } from "react-router-dom";
import Toast from "../components/toast.component";
import toast, { Toaster } from "react-hot-toast";
import ContactService from "../services/contact.service";

const Contactus = () => {

    const notify = (title, message, type) => {
        toast.custom(
            <Toast title={title} message={message} type={type} />, {
            duration: 4000,
        }
        );
    }

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            fullname: '',
            email: '',
            message: ''
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required('Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            message: Yup.string().required('Required')
        }),
        onSubmit: async (values, { resetForm }) => {
            if (formik.isValid) {                
                try {
                    ContactService.save(values)
                        .then(response => {
                            notify("Contact us", `Thank you for reaching out! Your message has been received.`, "SUCCESS");
                            resetForm();
                        })
                        .catch(responseError =>{
                            notify("Contact us", `We encountered an issue while submitting your message. Please try again later.`, "WARNING");
                            console.log(responseError)
                        });
                } catch (responsError) {
                    console.log(responsError);
                }

            }
        },
    });

    return (
        <div className="container">
            <Toaster />
            <div className="row d-flex justify-content-center" style={{ "marginTop": "150px", "padding": "13px" }}>
                <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5 col-12 mb-5">
                    <h3 style={{ fontSize:"2rem", fontWeight:"500", marginBottom:"28px" }}>Contact us</h3>
                    <p style={{ fontSize:"1.09rem", marginBottom:"26px"}}>Email, or complete the form to learn how  <br/>
                       <strong style={{ color:"#6797D2"}}>Bubeph</strong> can solve your messaging problem. </p>
                    <strong>contact@bubeph.com</strong>
                </div>

                <div className="col-xxl-2 col-lg-3 col-md-4 col-sm-5 col-12 shadow-sm p-3">
                    <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Get in Touch</h3>
                    <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>You can reach us anytime</h5>

                    <form onSubmit={formik.handleSubmit}>

                        <div className="" style={{ "position": "relative" }}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="fullname"
                                    name="fullname"
                                    placeholder="Your name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fullname}
                                />
                                {formik.touched.fullname && formik.errors.fullname ? (
                                    <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.fullname}</div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="email"
                                    name="email"
                                    placeholder="Example@exmaple.com"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.email}</div>
                                ) : null}
                            </div>

                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    type="text"
                                    name="message"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}
                                ></textarea>
                                {formik.touched.message && formik.errors.message ? (
                                    <div className="mt-1" style={{ "color": "#b71c1c", "fontSize": "0.8rem", "fontWeight": "bold" }}>{formik.errors.message}</div>
                                ) : null}
                            </div>

                            <div >
                                <button type="submit"
                                    className="btn btn-primary btn-sm"
                                    style={{ "backgroundColor": 'red !important' }}
                                >Submit</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contactus;