import axios from "axios";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/visitor`;

const save = (visitor) => {
    return axios.post(`${API_URL}/save`, visitor)
        .then((response) => {
            localStorage.setItem("visitor", JSON.stringify(response.data));
            return response.data;
        })
        .catch((responsError) => {
            const response = responsError.response;
            if (localStorage.getItem("visitor") === null && response.data != null) {
                localStorage.setItem("visitor", response.data);
            }
            return response.data;
        });
};


const findByVisitorId = (visitorId) => {
    return axios.get(`${API_URL}/${visitorId}`)
        .then((response) => {
            if (response.data != null) {
                localStorage.setItem("visitor", JSON.stringify(response.data));
                return;
            }
        })
        .catch((responseError) => {
            if (responseError.response.status === 404 && responseError.response.data == "") { // visitor not found
                save({ "visitorId": visitorId });
            }
        });
}

const findByVisitorId1 = (visitorId) => {
    return axios.get(`${API_URL}/${visitorId}`)
    // .then((response) => {
    //     if (response.data != null){
    //       localStorage.setItem("visitor", JSON.stringify(response.data));
    //       return;
    //     }          
    //   })
    // .catch( (responseError) => {
    //     if(responseError.response.status === 404 && responseError.response.data == ""){ // visitor not found
    //         save({"visitorId":visitorId});
    //     }
    // });
}

const generateFingerprint = async () => {
    const visitorId = await getCurrentBrowserFingerPrint().then((fingerprint) => {
        return fingerprint;
    })
    return visitorId;
}


const findByVisitorIdRR = async (visitorId) => {
    return axios.get(`${API_URL}/${visitorId}`)
        .then((response) => { return response.data; })
        .catch((responseError) => {
            console.error(responseError);
        });
}
const saveRR = async (visitor) => {
    return axios.post(`${API_URL}/save`, visitor)
        .then((response) => { return response.data; })
        .catch((responsError) => {
            console.error(responsError)
            return null;
        });
};
const registerVisitor = async () => {
    // must return the visitor 
    /*
    cas 1 
    check if visitor is in localstorage 
    if yes then check if this id is exist in database if yes then return the visitor 
    
    cas 2 if localstoarge empty then generate visitor id and check if the is is exist on db or not 
    */
    const visitor = VisitorService.getCurrentVisitor();
    if (visitor === null) {
        const visitorId = await generateFingerprint();
        const response = await findByVisitorIdRR(visitorId);
        if (response != null && response != "") {
            localStorage.setItem("visitor", JSON.stringify(response));
        }
        if (response === null || response === "") {
            const responseSave = await saveRR({ "visitorId": visitorId });
            if (responseSave != null) {
                localStorage.setItem("visitor", JSON.stringify(response));
            }
        }

    }
    if (visitor != null) {
        // check if not exist in database
        const response = await findByVisitorIdRR(visitor.visitorId);
        if (response === null || response === "") {
            const visitorId = await generateFingerprint();            
            const response = await findByVisitorIdRR(visitorId);
            if (response == null && response != "") {                
                const responseSave = await saveRR({ "visitorId": visitorId });
                if (responseSave != null) {
                    localStorage.setItem("visitor", JSON.stringify(response));
                }
            }
            if(response != null && response != ""){
                localStorage.setItem("visitor", JSON.stringify(response));
            }
        }

    }

    // if( visitor != null ){
    //     const res = VisitorService.findByVisitorId1(visitor.visitorId);
    //     return res.then( response => {
    //         const id = response.data.visitorId;
    //         if ( visitor.visitorId === id ){
    //             // return visitor
    //             return visitor;
    //         }else{
    //             // generate a new visitor id and save it 
    //             // and return it 
    //         }
    //     })
    //     .catch( error => console.log(error) );
    // }

};


const getCurrentVisitor = () => {
    return JSON.parse(localStorage.getItem("visitor"));
};

const VisitorService = {
    save,
    findByVisitorId,
    findByVisitorId1,
    getCurrentVisitor,
    registerVisitor
}

export default VisitorService;